import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Features from './components/Features';
import DemoForm from './components/DemoForm';
import FAQ from './components/FAQ';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import Video from './components/Video'

function App() {
  return (
    <div className="app">
      <Navbar />
      <div className="app-container">
        <Hero />
        <Video />
        <Features />
        <DemoForm />
        <FAQ />
        <Footer />
      </div>
      <ScrollToTop />
    </div>
  );
}

export default App;
