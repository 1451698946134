import React, { useRef } from 'react';
import { useInView } from '../hooks/useInView';

const DemoForm = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef);

  return (
    <div id="demo-section" className={`demo-section fade-in-section ${isInView ? 'is-visible' : ''}`} ref={sectionRef}>
      <div className="demo-header">
        <span className="gradient-text"> Book a Demo</span>
        <p>Deploy an AI agent on the phone, your website, and WhatsApp in just a few days!</p>
      </div>
      
      <form className="demo-form">
        <div className="form-field">
          <label>Full Name</label>
          <input type="text" placeholder="Jane Do" />
        </div>
        
        <div className="form-row">
          <div className="form-field">
            <label>Email Address</label>
            <input type="email" placeholder="jane.doe@mail.com" />
          </div>
          
          <div className="form-field">
            <label>Phone (Optional)</label>
            <input type="tel" placeholder="+44 77 1234 5678" />
          </div>
        </div>

        <div className="form-row">
          <div className="form-field">
            <label>Your company name</label>
            <input type="text" placeholder="Company name" />
          </div>
          
          <div className="form-field">
            <label>How many calls do you receive per month</label>
            <input type="text" placeholder="Number of calls" />
          </div>
        </div>

        <button type="submit" style={{"margin-left": "33rem"}} className="demo-hero-button">Book a demo</button>
      </form>
    </div>
  );
};

export default DemoForm; 