import React from 'react';

const Navbar = () => {
  const scrollToFAQ = () => {
    const faqSection = document.getElementById('faq-section');
    if (faqSection) {
      faqSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <nav className="navbar">
      <div className="navbar-brand">
        <img src="logo.png" alt="Logo"/>
      </div>
      {/* Wrap your buttons in a container */}
      <div className="navbar-buttons">
        <button className="demo-button" onClick={scrollToFAQ}>FAQs</button>
        <button className="demo-button" onClick={() => {
          const demoSection = document.getElementById('demo-section');
          if (demoSection) {
            demoSection.scrollIntoView({ behavior: 'smooth' });
          }
        }}>Book a Demo</button>
        <button
          className="demo-button"
          onClick={() => window.location.href = "https://dashboard.doora.co.uk"}
        >
          Doora Dashboard
        </button>
      </div>
    </nav>
  );
};

export default Navbar; 