import React, { useRef } from 'react';
import { useInView } from '../hooks/useInView';

const Features = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef);

  const features = [
    {
      title: "Easy to Understand Conversations",
      description: "Your customers can communicate naturally, just as they would with a human agent—responding to questions, changing topics, and expressing themselves freely.",
      image: "img1.webp" // Robot with headset on yellow background
    },
    {
      title: "Voice Agents handle over 80% of calls",
      description: "Your virtual agent autonomously handles a significant number of customer calls, only passing on qualified ones to human agents for further assistance.",
      image: "img2.webp" // Devices with microphone icon on blue background
    },
    {
      title: "System Integration",
      description: "Our team offers full onboarding, integrating Doora into your day-to-day processes and systems seamlessly.",
      image: "img3.webp" // Social media icons with voice interface on purple background
    },
    {
      title: "Easy to Action and Respond",
      description: "Doora simply and clearly organises all processed tasks for review, further action, or response.",
      image: "img4.webp" // New image for the fourth card
    }
  ];

  return (
    <section className={`features fade-in-section ${isInView ? 'is-visible' : ''}`} ref={sectionRef}>
      <h2>Deploy Your Voice Virtual <br></br> Agent Everywhere</h2>
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-image">
              <img src={feature.image} alt={feature.title} />
            </div>
            <div className="feature-content">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features; 