import React, { useState, useEffect } from "react";

const Hero = () => {
  const messages = [
    "Revolutionizing AI Communications",
    "Powering Your Business with AI",
    "AI Solutions for Estate Agents",
    "Smart AI for Smarter Conversations",
    "Your AI Assistant, 24/7",
  ];

  const [currentMessage, setCurrentMessage] = useState("");
  const [messageIndex, setMessageIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const typingSpeed = 100; // Speed of typing effect in ms
  const deleteSpeed = 50; // Speed of deleting effect in ms
  const pauseTime = 1500; // Time before deleting text

  useEffect(() => {
    const currentText = messages[messageIndex];

    if (charIndex < currentText.length) {
      const timeout = setTimeout(() => {
        setCurrentMessage((prev) => prev + currentText[charIndex]);
        setCharIndex((prev) => prev + 1);
      }, typingSpeed);
      return () => clearTimeout(timeout);
    } else {
      setTimeout(() => {
        const deleteInterval = setInterval(() => {
          setCurrentMessage((prev) => prev.slice(0, -1));
          setCharIndex((prev) => prev - 1);
        }, deleteSpeed);

        setTimeout(() => {
          clearInterval(deleteInterval);
          setMessageIndex((prev) => (prev + 1) % messages.length);
        }, deleteSpeed * currentText.length);
      }, pauseTime);
    }
  }, [charIndex, messageIndex]);

  return (
    <div className="hero-container">
      {/* Main Hero Section */}
      <div className="hero-section">
        <div className="hero-content">
          <div className="hero-message">
            {currentMessage}
            <span className="cursor" aria-hidden="true">|</span> {/* Cursor effect */}
          </div>
          <h1>Personalised AI Telecommunications</h1>
          <p>
            Your virtual agent powered by Upswing AI provides comprehensive solutions to 100% of questions asked and propositions raised. Answers 100% of phone calls, 24 hours a day, 365 days a year.
          </p>
          <div className="hero-buttons">
          <button className="demo-hero-button" onClick={() => {
          const demoSection = document.getElementById('demo-section');
          if (demoSection) {
            demoSection.scrollIntoView({ behavior: 'smooth' });
          }
        }}>Book a Demo</button>
          </div>
        </div>
        <div className="hero-avatar">
            <img src="doora.png" alt="AI Assistant Avatar" />
        </div>

      </div>
    </div>
  );
};

export default Hero;
