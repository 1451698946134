import React, { useRef } from 'react';
import { useInView } from '../hooks/useInView';

const Video = () => {
  const sectionRef = useRef(null);
  const isInView = useInView(sectionRef);

  return (
    <div className={`video-container fade-in-section ${isInView ? 'is-visible' : ''}`} ref={sectionRef}>
      <div className="video-header">
        <h2>
          Take a Look at <br></br>
          <span className="gradient-text"> Our Product</span>
        </h2>
      </div>
      <div className="video-section">
        {/* Add your product demo video here */}
        <video controls>
            <source src="/coming_soon.mp4" type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default Video; 